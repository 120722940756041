<template>
    <div class="py-2 w-full block mb-0" :style="'background: ' + data.Background" :class="['text-' + data.Color]">
        <div :class="data.Align === 'Center' ? 'mx-auto container' : ''">
            <div class="sm:block md:grid block gallery">
                <div v-for="(item, n) in data.Data" :key="n">
                    <img :src="item.Image" style="min-height: 100%;" class="w-full object-cover" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: Object
    }
}
</script>
<style lang="scss" scoped>
    .gallery {
        grid-template: repeat(2, 1fr) / repeat(3, 1fr);
        grid-gap: 0.5em;
        div:nth-child(1) {
            grid-column: span 2;
            grid-row: span 0;
        }
    }
</style>
